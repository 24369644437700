export default {
  text: '#202020',
  primary: '#f173ac',
  secondary: '#b3d334',
  tertiary: '#f49e3d',
  background: 'white',
  backgroundSecondary: 'lightgrey',
  light: '#FFF',
  dark: '#020303'
}
