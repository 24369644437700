export default {
  heading: 'Frunch, san-serif',
  body: 'Adam Cg Pro, sans-serif',
  monospace: 'Menlo, monospace',
  display: 'Cinzel, serif',
  googleFonts: [
    'Poiret One:100,200,300,400,500,600,700,800,900',
    'Open Sans:100,200,300,400,500,600,700,800,900',
    'Bungee',
    'Raleway:100,200,300,400,500,600,700,800,900'
  ],
  customFamilies: ['Brothers Bold', 'Adam Cg Pro', 'Frunch'],
  customUrls: [
    'https://www.gonation.biz/fonts/brothers-bold/brothers-bold.css',
    'https://www.gonation.biz/fonts/adam-cg-pro/adam-cg-pro.css',
    'https://www.gonation.biz/fonts/frunch/frunch.css'
  ]
}
