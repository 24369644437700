export default {
  // ? ==========================
  // ? =====  Multilocation  ====
  // ? ==========================

  multiLocation: {},

  // ? ===================
  // ? =====  Layout  ====
  // ? ===================

  navigation: {
    '.container': {
      padding: ['0.5rem', '0.5rem', '0.5rem', '1rem'],
      borderBottom: '1px solid',
      borderColor: 'primary',
      backgroundColor: 'white',
      color: 'dark'
    },
    '.containerScrolled': {
      borderBottom: '1x solid',
      borderColor: 'primary',
      padding: '0.25rem',
      '.logoLocationContainer': {
        // display: 'none',
        margin: ['', '', '', '0 auto 0 0'],
        position: ['', '', '', 'static'],
        '.container': {
          // padding: ['0.5rem'],
        },

        '.locationSwitcher': {
          justifyContent: 'center',
          button: {
            fontSize: '0.8rem'
          }
        },
        '.popUp': {
          transform: 'unset'
        }
      }
    },
    '.logoLocationContainer': {
      margin: ['', '', '', '0 auto 0 0'],
      position: ['', '', '', 'static'],
      padding: '0rem',

      '.logo': {
        img: {
          filter: 'unset',
          maxHeight: ['50px', '55px', '55px', '55px']
        }
      },
      '.logoScrolled ': {
        img: {
          // filter: 'brightness(0) invert(1)',

          maxHeight: ['30px', '50px', '50px', '50px'],
          height: 'unset'
        }
      }
    },

    '.smallNavMenu': {
      '.navItem': {
        margin: ['', '', '', '0.5rem', '0.5rem'],
        a: {
          color: 'primary',
          fontSize: ['1.2rem', '', '', '', '1.2rem'],
          fontWeight: 'bold',
          letterSpacing: '-0.5px',
          textTransform: 'lowercase',
          ':hover': {
            textDecoration: 'underline'
          }
        }
      },
      '.order-online': {
        // margin: '0rem',
        a: {
          color: 'primary',
          padding: '0.5rem',
          border: 'solid 1px',
          borderColor: 'primary'
        }
      }
    },

    '.hamburger': {
      backgroundColor: 'rgb(0 0 0 / 42%)',
      border: 'none',
      height: '45px',
      margin: ['auto 0rem auto auto', '', '', '0rem 0rem 0rem 0.5rem'],
      div: {
        backgroundColor: 'white'
      },
      'div:nth-of-type(2)': {
        width: '70%'
      }
    },
    '.navMenuOpen': {
      alignItems: ['', '', '', 'flex-end'],
      padding: ['', '', '', '4rem'],
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
      width: ['85%', '60%', '50%', '40%'],
      '.navMenuLogo': {
        position: ['', '', '', ''],
        width: ['', '', '', '100%'],
        maxWidth: '300px',
        left: '1rem',
        margin: '0rem 0.5rem 1rem',
        img: {
          maxHeight: ['', '', '', 'unset'],
          maxWidth: ['', '', '', 'unset'],
          width: ['', '', '', '100%']
        }
      },
      '.navItem': {
        a: {
          textTransform: 'lowercase',
          fontSize: ['0.9rem', '1rem', '1.25rem', '1.25rem']
        }
      },
      '.seperatorLine': {
        margin: ['', '', '', '1rem 0 1rem auto']
      },
      '.phoneSocialContainer': {
        flexDirection: ['', '', '', 'column'],
        alignItems: ['', '', '', 'flex-end'],
        textAlign: ['', '', '', 'right'],
        a: {
          textAlign: ['', '', '', 'right'],
          justifyContent: ['', '', '', 'flex-end'],
          fontSize: '1rem'
        }
      },
      '.smallNavHeading ': {
        textAlign: ['', '', '', 'right']
      },

      '.socialIconsContainer': {
        flexDirection: ['', '', '', 'column'],
        alignItems: ['', '', '', 'flex-end'],
        textAlign: ['', '', '', 'right'],
        '.smallNavHeading ': {
          textAlign: 'right'
        },
        a: {
          textAlign: ['', '', '', 'right'],
          justifyContent: ['', '', '', 'flex-end'],
          fontSize: '1rem',
          color: 'white',
          svg: {
            path: { fill: 'dark' }
          }
        },
        '.socialContainer': {
          flexDirection: ['', '', '', 'row']
        }
      },

      '.navItemDropdownMenu': {
        position: ['static', 'static', 'static', 'static'],
        textShadow: 'none'
      },
      '.navItemDropdown': {
        display: 'flex',
        flexDirection: 'column'
      },
      '.phoneContainer': {}
    },

    '.navItemDropdownMenu': {
      backgroundColor: 'white',
      width: 'fit-content'
    },

    '.navBlockOpen': {
      width: ['15%', '40%', '50%', '60%'],
      left: '0rem',
      backgroundSize: 'cover',
      backgroundPosition: 'center center'
    }
  },

  footer: {
    backgroundColor: 'white',
    maxWidth: 'unset',
    padding: ['3rem 1rem 7rem', '', '2rem 5rem 2rem'],
    '.logo': {
      // filter: 'brightness(0) invert(1)',
    },
    borderTop: 'solid 5px',
    borderColor: 'primary',
    // '.multiButtonContainer': {
    //   a: {
    //     variant: 'buttons.primary',
    //   },
    // },
    '.gonationLogo': {
      width: 'fit-content'
    },
    '.contactDetails-container': {
      display: 'none'
    },
    '.socialContainer svg path': {
      fill: 'dark'
    }
  },

  ctaWidget: {},

  // ? ====================
  // ? ==== Reuseables ====
  // ? ====================

  title: {
    fontSize: ['2.5rem', '2.75rem', '3rem', '3rem', '3.25rem'],
    fontWeight: 'normal',
    letterSpacing: '1px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    textAlign: 'left',
    marginBottom: '1.5rem',
    order: '2',
    textTransform: 'lowercase'
  },
  subtitle: {
    color: 'primary',
    width: 'fit-content',
    textAlign: 'left',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: ['1.2rem', '1.4rem', '1.7rem'],
    marginBottom: '1.5rem',

    order: '1',
    border: 'none',
    fontWeight: 'bold',
    fontFamily: 'heading',
    textTransform: 'capitalize'
  },
  text: {
    textAlign: 'left',
    lineHeight: '1.75',
    fontSize: '0.95rem',
    order: '3',
    p: {
      lineHeight: '1.75',
      letterSpacing: '0.5px',
      textAlign: 'inherit',
      fontSize: 'inherit'
    },
    'p:last-child': {
      margin: '0rem'
    }
  },

  sideBySide1: {
    flexWrap: 'wrap',
    padding: ['0rem', '0rem', '0rem', '0rem'],
    '.lazyload-wrapper': {
      width: ['', '', '50%', '60%'],
      img: {
        maxHeight: ['', '', '', '80vh']
      }
    },
    '.content': {
      padding: ['2rem 1rem 2rem 1rem', ' 2.5rem', '2.5rem', '3rem', '4rem'],
      backgroundColor: 'tertiary',
      minHeight: '60vh',
      width: ['', '', '50%', '40%'],
      color: 'white'
    },
    '.title': {
      variant: 'customVariants.title',
      fontSize: ['1.5rem', '1.5rem', '1.75rem', '2rem', '2.25rem']
    },
    '.subtitle': { variant: 'customVariants.subtitle' },
    '.text': {
      variant: 'customVariants.text'
    },
    a: {
      order: '4'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  centerBlock: {
    minHeight: '90vh',

    '.section': {
      padding: ['2rem 1rem 2rem 3rem', '', '3rem 2rem'],
      alignItems: 'flex-start',
      justifyContent: 'flex-start'
    },
    '.title': {
      variant: 'customVariants.title'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle'
    },

    '.text': {
      variant: 'customVariants.text'
    },
    a: {
      order: '4'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  // ? ====================
  // ? ====  Homepage  ====
  // ? ====================

  homepageHero: {
    variant: 'customVariants.centerBlock',
    '.hero_content_container': {
      right: '2rem',
      width: '95%',
      color: 'white',
      alignItems: 'center',
      //   transform: 'translate(-50%, -50%)',
      marginLeft: '0rem',
      maxWidth: '1000px',
      border: 'none',
      '*': {
        textAlign: 'center'
      },
      '.title': {
        fontSize: ['3rem', '3.5rem', '4rem', '4.5rem', '5rem'],
        textShadow: '2px 2px 20px black'
      },
      '.subtitle': {
        fontSize: ['2rem', '2.5rem', '2.75rem', '3rem', '3.25rem'],
        color: 'secondary',
        textShadow: '2px 2px 20px black',
        order: '2',
        fontWeight: 'bold'
      }
    }
  },

  homepageShout: {
    '.title': {
      fontFamily: 'heading',
      textTransform: 'initial'
    },
    '.text': {
      padding: '2rem',
      color: 'white',
      backgroundColor: 'tertiary',
      fontFamily: 'body',
      fontWeight: 'bold',
      fontSize: ['1.4rem', '', '2rem'],
      lineHeight: '1.6'
    }
  },

  titleSection: {
    variant: 'customVariants.centerBlock',
    backgroundAttachment: ['', '', 'unset'],
    '::before': {
      position: 'absolute',
      top: '0rem',
      left: '0rem',
      content: '""',
      width: '100%',
      height: '100%',
      background:
        'linear-gradient(320deg, rgba(255,255,255,0) 0%,  rgba(255,255,255,0) 40%,  rgba(255,255,255,1) 75%, rgba(255,255,255,1) 100%)'
    },
    'div.section': {
      position: 'relative',
      margin: '0rem',
      padding: '0rem',
      '*': {
        color: 'primary'
      }
    }
  },

  titleSection2: {
    variant: 'customVariants.centerBlock',
    backgroundColor: 'secondary',
    color: 'primary',
    minHeight: 'unset',
    'div.section': {
      padding: '0rem',
      alignItems: 'center',
      textAlign: 'center',
      margin: '0rem',
      maxWidth: 'unset',
      width: '100%'
    }
  },

  homepageMenu: {
    variant: 'customVariants.centerBlock',
    // backgroundAttachment: ['', '', 'unset'],
    '::before': {
      position: 'absolute',
      top: '0rem',
      left: '0rem',
      content: '""',
      width: '100%',
      height: '100%',
      background:
        'linear-gradient(320deg, rgba(255,255,255,0) 0%,  rgba(255,255,255,0) 40%,  rgba(255,255,255,1) 75%, rgba(255,255,255,1) 100%)'
    },
    'div.section': {
      position: 'relative',
      margin: '0rem',
      padding: '0rem',
      '.title, .subtitle, .text': {
        color: 'primary'
      },
      '.text p': {
        color: 'tertiary',
        fontWeight: 'bold'
      }
    }
  },

  homepageBoxes: {
    position: 'relative',
    '::before': {
      variant: 'customVariants.title',
      content: '"Our Offerings"',
      position: ['', '', 'absolute'],
      top: '0rem',
      left: '0rem',
      writingMode: ['', '', 'tb'],
      padding: '1rem',
      color: 'primary',
      fontQeight: 'bold'
    },
    '.boxes': {},
    '.box': {
      alignItems: 'center',
      textAlign: 'center',
      width: ['100%', '50%', '33.33%', '27.5%'],
      '.title': {
        border: 'none',
        fontSize: ['1.25rem'],
        color: 'primary',
        textTransform: 'lowercase',
        fontFamily: 'body',
        fontWeight: 'bold',
        mt: '0.5rem'
      },
      '.text': {
        p: {
          fontSize: '0.8rem'
        }
      },
      img: {
        width: '100%'
      }
    }
  },
  locationsTitle2: {
    variant: 'customVariants.titleSection2'
  },
  homepageOlo: {
    variant: 'customVariants.sideBySide1'
  },
  homepageJoin: {
    variant: 'customVariants.sideBySide1'
  },
  homepageGiftCards: {
    variant: 'customVariants.sideBySide1',
    'div.content': {
      color: 'text',
      background: 'none'
    }
  },
  homepageContact: {
    color: 'white',
    minHeight: '60vh',
    '.text *': {
      color: 'white'
    }
  },

  homepageHeroShout: {
    display: 'none!important'
  },

  // ? ========================
  // ? =====  Venue page  =====
  // ? ========================

  venueRestaurant: {
    variant: 'customVariants.sideBySide1'
  },
  venueOutdoor: {
    variant: 'customVariants.sideBySide1'
  },
  venueBar: {
    variant: 'customVariants.sideBySide1'
  },

  // ? ========================
  // ? =====  About page  =====
  // ? ========================

  aboutSection: {
    '.title': {
      borderBottom: '2px solid',
      borderColor: 'primary'
    }
  },

  // ? ========================
  // ? ====  Menu page  ====
  // ? ========================

  menu: {
    '.menuItemName, .menuSectionTitle': {
      textTransform: 'none'
    },
    '.menuTab': { color: 'white' },
    '.allInContainerWrapper > .allInContainer > .menuContainer .menuSectionTitle': {
      display: 'none'
    }
  },

  // ? ========================
  // ? ====  Gallery page  ====
  // ? ========================

  gallery: {
    marginTop: '120px',
    '.albumTitle': {
      padding: '0.5rem'
    },
    '.sauces ': {
      '.albumImage': {
        objectFit: 'contain',
        gridRowEnd: 'span 2'
      }
    }
  },

  // ? ========================
  // ? ====  Events page  ====
  // ? ========================

  events: {
    '.events-container': {
      maxWidth: 'unset'
    },
    '.no-events-container': {
      padding: '10rem 1rem',
      img: {
        maxHeight: '70px'
        // filter: 'brightness(0) invert(1)',
      }
    },

    '.eventItemImage': {
      minHeight: 'unset',
      width: '25%'
      // height: '100%',
    },
    '.eventDays, .eventDate': {
      color: 'primary'
    },
    '.eventItemDescription': {
      maxHeight: '250px',
      overflowY: 'scroll',
      '::-webkit-scrollbar': {
        display: 'none'
      },
      '-ms-overflow-style': 'none' /* IE and Edge */,
      scrollbarWidth: 'none'
    }
  },

  // ? ========================
  // ? ====  Contact page  ====
  // ? ========================

  contactForm: {
    order: '4',
    background: 'none',
    backgroundColor: 'primary',
    h2: {
      variant: 'customVariants.title',
      order: 'unset',
      fontSize: ['1.75rem', '2rem', '2rem', '2.5rem']
    },
    backgroundPosition: 'center center',
    color: 'white',
    padding: ['3rem 1rem', '', '6rem 1rem'],
    '.inputField, .textField ': {
      fontWeight: '300',
      borderColor: 'white',
      borderWidth: '2px',
      borderRadius: '0px',
      padding: '1rem 0.5rem',
      color: 'white',
      '::placeholder': {
        color: 'white'
      }
    },

    '.submitBtn': {
      variant: 'buttons.secondary',
      height: 'unset',
      width: 'auto'
    }
  },

  hours: {},

  locationMap: {
    order: '3',
    '.content_container': {
      padding: '0rem'
    },
    h3: {
      variant: 'customVariants.title',
      justifyContent: 'center',
      display: 'flex',
      order: 'unset',
      width: '100%',
      backgroundColor: 'primary',
      color: 'white',
      padding: '1rem 0.5rem 1rem',
      textAlign: 'center',
      fontSize: ['1.4rem', '1.5rem', '1.75rem', '1.75rem', '2rem'],
      textAlign: 'center',
      alignItems: 'center'
    },
    '.contactDetails-container': {
      padding: '0rem 1rem 3rem'
    },
    '.hours-section': {
      maxWidth: 'unset',
      padding: '0rem',
      '.hoursContainer': {
        padding: ['1rem', '2rem', '3rem 4rem', '3rem 5rem']
      }
    },
    '.dayofWeekText': {
      margin: '0 auto 1rem 0',
      borderBottom: '1px solid'
    },
    '.textContent': {
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      display: 'flex'
    }
  },

  // ? ============================
  // ? ====  Reservations page  ===
  // ? ============================

  reservationsSection: {
    justifyContent: 'center',
    alignItems: 'center',
    padding: '3rem 1rem',
    flexDirection: 'column',
    '.textContent': {
      marginBottom: '2rem',
      textAlign: 'center'
    },
    '.title': {
      fontSize: ['2rem', '', '2.5rem'],
      fontWeight: '400'
    }
  },

  // ? ============================
  // ? ===  Private Dining page  ==
  // ? ============================

  privateDiningHero: {
    '.hero_content_container': {
      backgroundColor: 'rgb(0 0 0 / 69%)',
      '.title': {
        variant: 'customVariants.title'
      },
      '.subtitle': {
        variant: 'customVariants.subtitle',
        color: '#8a242c'
      },
      '.text': {
        variant: 'customVariants.text'
      },
      a: {
        order: '4'
      },
      '.ctaButton': {
        variant: 'buttons.primary'
      }
    }
  },

  privateDiningHeroShout: {
    display: 'none'
  },

  // ? ========================
  // ? ====  Press page  ====
  // ? ========================

  pressMenu: {
    padding: ['1rem', '1rem', '1rem', '1rem'],
    backgroundColor: 'background',
    '.textContent': {
      backgroundColor: 'black'
    },
    '.imageContainer': {
      backgroundColor: '#8a7d74',
      img: {
        objectFit: 'contain',
        padding: '1rem'
      }
    }
  },

  pressPage: {
    backgroundColor: 'background'
  }
}
